.main {
  display: grid;
  gap: 1rem;
  justify-content: center;
  place-content: center;
  justify-items: center;
  padding-bottom: 100px;
  overflow: auto;
}

.tabContainer {
  width: 100%;
  max-width: 500px;
  padding-inline: 2rem;
}

.tabSelect {
  display: flex;
  justify-content: stretch;
  gap: 0.125rem;
  padding: 0.125rem;
  margin: 0 auto 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
}

.tabSelect > * {
  width: 100%;
}

.tabContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.95rem;
  padding: 2.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
}
